/* eslint react/destructuring-assignment: 0 */
import React from 'react'
import { graphql, Link } from 'gatsby'
import { kebabCase } from 'lodash'

import styled from '@emotion/styled'
import { Layout, WrapperTwo, Title, SEO, Header } from '../components'
import website from '../../config/website'

const List = styled.div`
  margin-top: 3rem;
  a {
    display: block;
    font-size: 1.25rem;
    margin: 1rem 0;
  }
`

const LocaleSwitcherStyle = theme => css`
  [data-name='locale-switcher'] {
    color: ${theme.colors.greyBlue};
    a {
      color: white;
    }
  }
`
const StyledWrapperTwo = styled(WrapperTwo)`
  min-height: calc(100vh - 290px);
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    min-height: calc(100vh - 250px);
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
  }
`

const Headline = styled.p`
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: ${props => props.theme.colors.greyBlue};
  font-size: 1.25rem;
  a {
    font-style: normal;
    font-weight: normal;
  }
`
const Hero = styled.header`
  background-color: ${props => props.theme.colors.orangeLight};
  padding-top: 1rem;
  padding-bottom: 2rem;
  color: ${props => props.theme.colors.white};
  div {
    padding-top: 3em;
  }
  h1 {
    color: ${props => props.theme.colors.white};
  }
`

class Categories extends React.Component {
  render() {
    const {
      data: { categories },
    } = this.props
    return (
      <Layout>
        <SEO title="Contact" pathname="contact" />
        <Header invert />
        <Hero>
          <WrapperTwo>
            <Headline>Category:</Headline>
          </WrapperTwo>
        </Hero>
        <StyledWrapperTwo>
          <Title>
            {categories.edges.map(cat => (
              <Link key={cat.node.data.name} to={`/categories/${kebabCase(cat.node.data.name)}`}>
                <p>{cat.node.data.name}</p>
              </Link>
            ))}
          </Title>
        </StyledWrapperTwo>
        <ul />
      </Layout>
    )
  }
}

export default Categories

export const pageQuery = graphql`
  query CategoriesQuery {
    categories: allPrismicCategory {
      edges {
        node {
          data {
            name
          }
        }
      }
    }
  }
`
